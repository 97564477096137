<template>
  <v-card
    class="mx-auto"
    outlined
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            color="primary"
            href="https://app.smartsheet.com/b/form/de21913ed48843079a055073df861a9e"
            target="_blank"
          >
            Ayúdanos contestando la encuesta
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  mounted() { },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {},
  setup() { },
}
</script>
