<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <encuesta></encuesta>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <que-hacer></que-hacer>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <propagacion></propagacion>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <telefono></telefono>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Telefono from './Telefono'
import QueHacer from './QueHacer'
import Propagacion from './Propagacion'
import Encuesta from '../resumen/Encuesta'

export default {
  components: {
    Propagacion,
    QueHacer,
    Telefono,
    Encuesta,
  },
  mounted() { },
  methods: {},
  setup() { },
}
</script>
