<template>
  <v-card>
    <v-card-title>
      <p class="text-center">
        <span class="font-weight-semibold">¿Qué hacer si se siente enfermo?</span>
      </p>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
      </v-sheet>
      <p class="text-lg-center">
        <span class="font-weight-semibold">Si se está enfermo con COVID-19, o sospecha que puede estar infectado con el virus que causa COVID-19, siga
          los pasos en esta hoja informativa para ayudar a prevenir la propagación del virus a las personas en su hogar
          y comunidad.</span>
      </p>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <p class="text-lg-center">
              <a
                class="img-coronavirus"
                href="https://tequila-documentos.s3.us-east-2.amazonaws.com/que_hacer_si_tengo_covid.pdf"
                target="_blank"
              >
                <v-img
                  class="img-coronavirus"
                  :src="require('../../assets/images/salud/previa_que_hacer.png')"
                ></v-img>
              </a>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.fillData()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData() { },
  },
  setup() { },
}
</script>
