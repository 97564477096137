<template>
  <v-card>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
      </v-sheet>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-img :src="require('../../assets/images/salud/numero_diagnostico.jpg')"></v-img>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.fillData()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData() { },
  },
  setup() { },
}
</script>
