<template>
  <v-card>
    <v-card-title class="align-start">
      <p class="text-lg-center">
        <span class="font-weight-semibold">¿Qué es y cómo evitar su progragación COVID-19?</span>
      </p>
    </v-card-title>
    <v-sheet
      v-if="loading"
      :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
      class="pa-3"
    >
    </v-sheet>
    <p class="text-lg-center">
      <span class="font-weight-semibold">COVID-19 causa enfermedades respiratorias en las personas y puede propagarse de persona en persona. Para ayudar
        a prevenir la propagación de enfermedades respiratorias como COVID-19, consulte nuestra guía.</span>
    </p>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="offset-1"
        >
          <p class="text-lg-center">
            <a
              class="img-coronavirus"
              href="https://tequila-documentos.s3.us-east-2.amazonaws.com/que_es_y_evitar_propagacion_covid.pdf"
              target="_blank"
            >
              <v-img
                class="img-coronavirus"
                :src="require('../../assets/images/salud/previa_evitar_propagacion.jpg')"
              ></v-img>
            </a>
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.fillData()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData() { },
  },
  setup() { },
}
</script>
